<template>
    <div class="container-fluid">
        <div class="card search-card">
            <div class="card-body">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <div class="form-row">
                        
                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label for="ManpowerDemandNo">
                                工號
                                </label>
                                <input
                                    type="text"
                                    class="form-control"
                                    id="BadgeNo"
                                    placeholder="請輸入關鍵字"
                                    v-model="searchInfo.BadgeNo"
                                />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>姓名</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder="請輸入關鍵字"
                                    v-model="searchInfo.Name"
                                />
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>起始日期</label>
                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" :clearable="false" :picker-options="StartDatepickerOptions" v-model="searchInfo.StartDate">
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>結束日期</label>
                                <el-date-picker type="date" placeholder="請輸入日期" value-format="yyyy-MM-dd" class="date" :clearable="false" :picker-options="EndDatepickerOptions" v-model="searchInfo.EndDate">
                                </el-date-picker>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>是否填寫</label>
                                <select class="form-control" v-model="searchInfo.IsFilled">
                                    <option value=""></option>
                                    <option value="未填">未填</option>
                                    <option value="已填">已填</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>快篩結果</label>
                                <select class="form-control" v-model="searchInfo.RapidTestResult">
                                    <option value=""></option>
                                    <option value="陰性">陰性</option>
                                    <option value="陽性">陽性</option>
                                    <option value="無效反應">無效反應</option>
                                    <option value="本人今日沒有做快篩">本人今日沒有做快篩</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <div class="form-group">
                                <label>是否上傳快篩照片</label>
                                <select class="form-control" v-model="searchInfo.IsUpload">
                                    <option value=""></option>
                                    <option value="未上傳">未上傳</option>
                                    <option value="已上傳">已上傳</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-3">
                            <button
                                type="button"
                                class="btn btn-primary btn-search"
                                style="margin-top: 30px;"
                                @click="handleSubmit(getPersonalHealthQuestionnaireList(1, tableInfo.per_page))"
                            >
                                查詢
                            </button>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </div>

        <div class="yahome-table-wrap">
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-info mb-2 mr-2" @click="exclusionCount++">排除名單</button>
                <button type="button" class="btn btn-info mb-2" @click="exportFile">匯出</button>
            </div>
            <VueBootstrap4Table
                :rows="rows"
                :columns="columns"
                :config="config"
                class="opening-table"
                @on-change-query="onChangeQuery"
                :total-rows="total"
            >
                <!-- 快篩圖片 -->
                <template slot="UploadResult" slot-scope="scope">
                    <img v-if="scope.row.IsUpload === '已上傳'" class="uploadImg" :src="scope.row.RapidTestBase64">
                </template>
                <!-- 明細 -->
                <template slot="Detail" slot-scope="scope">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click.prevent="viewDetail(scope.row.Guid)"
                        v-if="scope.row.CreateTime !== null"
                    >
                        明細
                    </button>

                    <div v-else style="height: 38px"></div>
                </template>
                <template slot="empty-results"> 暫無資料 </template>
            </VueBootstrap4Table>
        </div>

        <detailModal>
            <div class="form-group">
                <label>工號</label>
                <div class="detail-control">A6545</div>
            </div>
            <div class="form-group">
                <label>姓名</label>
                <div class="detail-control">張＊涵</div>
            </div>
            <div class="form-group">
                <label>座位號碼</label>
                <div class="detail-control">A棟_黃區</div>
            </div>
            <div class="form-group">
                <label>非指定座位原因</label>
                <div class="detail-control textarea"></div>
            </div>
            <div class="form-group">
                <label>其他建議</label>
                <div class="detail-control textarea"></div>
            </div>
        </detailModal>

        <Detail />

        <ExclusionModal :exclusionCount="exclusionCount" @getPersonalHealthQuestionnaireList="getPersonalHealthQuestionnaireList(tableInfo.page, tableInfo.per_page)" />
    </div>
</template>

<script>
/* global $ */
import moment from "moment";
// import QrcodeVue from "qrcode.vue";
// 複製
// import Clipboard from "clipboard";

// import DemandDetailModal from "@/components/back/Modal/DemandDetail";

import { config } from "@/components/table_config.js";

import detailModal from '@/components/Modal/detailModal.vue'
import Detail from '@/components/Modal/healthDetail.vue'
import ExclusionModal from '@/components/Modal/ExclusionModal.vue'

import { apiGetPersonalHealthQuestionnaireList, apiExportPersonalHealthQuestionnaire } from '@/apis/questionnaire'
import { mapActions } from 'vuex'

export default {
    name: "OpeningManage",
    components: {
        detailModal,
        Detail,
        ExclusionModal,
    },
    data() {
        return {
            rows: [],
            columns: [
                {
                    label: "填寫時間",
                    name: "CreateTime",
                },
                {
                    label: "是否填寫",
                    name: "IsFilled",
                },
                {
                    label: "工號",
                    name: "BadgeNo",
                    // sort: true,
                },
                {
                    label: "姓名",
                    name: "Name",
                },
                {
                    label: "快篩結果",
                    name: "RapidTestResult",
                },
                {
                    label: "快篩照片",
                    name: "UploadResult",
                    slot_name: "UploadResult",
                },
                {
                    label: "檢視明細",
                    name: "Detail",
                    slot_name: "Detail",
                },
            ],
            config: {
                ...config,
                server_mode: true,
            },
            searchInfo: {
                StartDate: '',
                EndDate: '',
            },
            tableInfo: {
                // 分頁資訊
                page: 1,
                per_page: 10,
            },
            // questionnaire: {},
            choiceGuid: '',
            total: 0,
            exclusionCount: 0,
            StartDatepickerOptions: {
                disabledDate(date) {
                    const beforeDay30 = moment().subtract(30, 'days').format("YYYY-MM-DD")
                    const today = moment().format("YYYY-MM-DD");

                    return date < new Date(beforeDay30).getTime() || date > new Date(today).getTime() 
                },
            },
            EndDatepickerOptions: {},
        };
    },
    filters: {
        moment: function(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
    watch: {
        "searchInfo.StartDate": function() {    //  重新 disabled 日期
            let StartDate = moment(this.searchInfo.StartDate).subtract(1, 'days').format("YYYY-MM-DD")
            const today = moment().format("YYYY-MM-DD");

            this.EndDatepickerOptions = {
                disabledDate(date) {
                    return date < new Date(StartDate).getTime() || date > new Date(today).getTime() 
                },
            }
            
        },
    },

    methods: {
        ...mapActions(['updateLoading']),
        ///////////////////////////  多國語系  ///////////////////////////////////////////
        i18n(keyStr) {
            return this.$t(keyStr);
        },
        getPersonalHealthQuestionnaireList(page = 1, per_page = 10) {

            this.updateLoading(true)
            apiGetPersonalHealthQuestionnaireList({
                ...this.searchInfo,
                Page: page,
                PerPage: per_page,
            }).then((response) => {
                // console.log(response)

                this.total = response.data.DataQuantity

                this.rows = response.data.Dtos.map(item => {
                    return {
                        ...item,
                        CreateTime: item.IsFilled !== "未填" ? moment(item.CreateTime).format("YYYY-MM-DD HH:mm:ss") : moment(item.CreateTime).format("YYYY-MM-DD"),
                    }
                })

                this.updateLoading(false)
            })
        },
        onChangeQuery(queryParams) {
            const vm = this;
            console.log('queryParams', queryParams)
            // console.log(JSON.parse(JSON.stringify(queryParams)));

            vm.tableInfo = {
                page: queryParams.page,
                per_page: queryParams.per_page,
            };

            vm.getPersonalHealthQuestionnaireList(
                vm.tableInfo.page,
                vm.tableInfo.per_page,
            );
        },
        viewDetail(Guid) {

            // this.choiceGuid = Guid
            this.$store.dispatch("updateWatchGuid", Guid);

            // apiGetPersonalHealthQuestionnaire({
            //     guid: Guid
            // }).then((response) => {
            //     console.log(response)
            //     this.questionnaire = response.data
            // })
        },
        exportFile() {

            this.updateLoading(true)
            apiExportPersonalHealthQuestionnaire({
                ...this.searchInfo,
            }).then((response) => {
                console.log(response)
                if(response.status === 200) {
                    const blob = new Blob([response.data], { 'type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = `個人疫調報表.xlsx`;
                    a.click();
                    window.URL.revokeObjectURL(url);

                    this.updateLoading(false)
                }
            })
        }
    },
    created() {
        this.searchInfo.StartDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
        this.searchInfo.EndDate = moment().format("YYYY-MM-DD");

        this.EndDatepickerOptions = {
            disabledDate(date) {
                const beforeDay30 = moment().subtract(30, 'days').format("YYYY-MM-DD")
                const today = moment().format("YYYY-MM-DD");

                return date < new Date(beforeDay30).getTime() || date > new Date(today).getTime() 
            },
        };


    },
    mounted() {
        this.getPersonalHealthQuestionnaireList()
    },

    destroyed() {
        $(".modal").modal("hide");
        $("body").removeClass("modal-open");
        $(".modal-backdrop").remove();
    },
};
</script>

<style lang="scss" scoped>
.detail-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
}

.textarea {
    min-height: 140px;
}

.date {
    display: block;
}

.el-date-editor.el-input {
    width: 100%;
}

// .uploadImg:hover{
// 	transform: scale(2.0);
// }
</style>
