<template>
  <transition
    v-if="showModal"
    name="custom-classes-transition"
    enter-active-class="animated bounceInLeft"
    leave-active-class="animated bounceOutRight"
  >
    <div class="detail-modal">
      <div class="detail-modal-inner">
        <div class="menu">
          <div class="close-show-detail" @click="closeModal">
            <eva-icon
              name="close-circle"
              fill="#FFF"
              width="50"
              height="50"
            ></eva-icon>
          </div>
        </div>
        <div class="page-wrap">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated flipInY"
          >
            <perfect-scrollbar class="page">
              <section class="page-inner">
                <form class="demand-form">
                  <div class="tab-content">
                    <div class="card search-card">
                        <div class="card-body">
                            <ValidationObserver v-slot="{ handleSubmit }">
                                <div class="form-row">
                                    
                                    <div class="col-sm-12 col-md-2">
                                        <div class="form-group">
                                            <label for="ManpowerDemandNo">
                                            關鍵字
                                            </label>
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="BadgeNo"
                                                placeholder="請輸入關鍵字"
                                                v-model="searchInfo.Keyword"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <label>報表排除顯示</label>
                                            <select class="form-control" v-model="searchInfo.Excluded">
                                                <option :value="null"></option>
                                                <option :value="false">未排除</option>
                                                <option :value="true">已排除</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="col-sm-12 col-md-2">
                                        <button
                                            type="button"
                                            class="btn btn-primary btn-search"
                                            style="margin-top: 30px; margin-left: 10px"
                                            @click="handleSubmit(getStaffPages(1, tableInfo.per_page))"
                                        >
                                            查詢
                                        </button>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                    </div>

                    <div class="yahome-table-wrap">
                        <VueBootstrap4Table
                            :rows="rows"
                            :columns="columns"
                            :config="config"
                            class="opening-table"
                            @on-change-query="onChangeQuery"
                            :total-rows="total"
                        >
                            <!-- 明細 -->
                            <template slot="Excluded" slot-scope="scope">
                                <el-switch
                                    v-model="scope.row.Excluded"
                                    active-color="#13ce66"
                                    @change="changeStaffExcluded(scope.row)">
                                </el-switch>
                            </template>

                            
                            <template slot="empty-results"> 暫無資料 </template>
                        </VueBootstrap4Table>
                    </div>
                  </div>
                </form>
              </section>
            </perfect-scrollbar>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";

import { apiGetStaffPages, apiChangeStaffExcluded } from '@/apis/questionnaire'
import { config } from "@/components/table_config.js";

export default {
  name: "ExclusionModal",
  props: ['exclusionCount'],
  data() {
    return {
        showModal: false,

        questionnaire: {},
        details: [],

        rows: [],
        columns: [
            {
                label: "是否排除",
                name: "Excluded",
                slot_name: "Excluded",
            },
            {
                label: "工號",
                name: "BadgeNo",
                // sort: true,
            },
            {
                label: "姓名",
                name: "Name",
            },
        ],
        config: {
            ...config,
            server_mode: true,
        },
        searchInfo: {},
        tableInfo: {
            // 分頁資訊
            page: 1,
            per_page: 10,
        },
        total: 0,
    };
  },

  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    demandStatus: function(status) {
      switch (status) {
        case "New":
          return "新建";
        case "Pending":
          return "待決";
        case "Approved":
          return "同意";
        case "Suspend":
          return "暫停";
        case "Cancelled":
          return "作廢";
      }
    },
  },

  computed: {
    ...mapGetters(["lang", "watchReportGuid"]),
  },

  watch: {
    exclusionCount() {
        this.getStaffPages()
    },
  },

  methods: {
    ...mapActions(['updateLoading']),
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },

    closeModal() {
      this.$store.dispatch("updateWatchGuid", '');

      this.$emit('getPersonalHealthQuestionnaireList')

      this.showModal = false;
    },

    onChangeQuery(queryParams) {
        const vm = this;
        console.log('queryParams', queryParams)

        vm.tableInfo = {
            page: queryParams.page,
            per_page: queryParams.per_page,
        };

        vm.getStaffPages(
            vm.tableInfo.page,
            vm.tableInfo.per_page,
        );
    },

    getStaffPages(page = 1, per_page = 10) {    //  排除人員

        this.updateLoading(true)
        apiGetStaffPages({
            ...this.searchInfo,
            Page: page,
            PerPage: per_page,
        }).then((response) => {
            // console.log(response)

            this.total = response.data.DataQuantity

            this.rows = response.data.Dtos

            this.showModal = true

            this.updateLoading(false)
        })
    },

    changeStaffExcluded(item) {
        
        this.updateLoading(true)
        apiChangeStaffExcluded({
            BadgeNo: item.BadgeNo,
            Excluded: item.Excluded,
        }).then((response) => {
            // console.log(response)

            if(response.status === 200) {
                this.$notify({
                    title: "成功",
                    message: "成功變更狀態",
                    type: "success",
                    duration: 3500,
                });
            }

            this.updateLoading(false)
        })
    },

    dateFormat: function(date) {
      return moment(date).format("YYYY-MM-DD");
    },

  },

  destroyed() {
    this.showModal = false;
  },
};
</script>

<style lang="scss" scoped>
.detail-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0,0,0,.9);
}

.detail-modal {
  z-index: 2000;
  .detail-modal-inner {
    .menu {
      width: 0%;
    }
    .page-wrap {
      background-image: none;
      .page {
        max-width: 960px;
        margin: 25px auto;
      }
    }
    pre {
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }
}

.demand-form {
  vertical-align: middle;
}

.test {
  background-color: gray;
  color: white;
}

.demand-form .tab-content {
    background-color: transparent;
}

.detail-modal .detail-modal-inner .page-wrap .page {
    background-color: #f1f1f1;
}
</style>
